import React from "react";
import { Typography } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";

export const EvaluableCategoryItem = ({ item }) => {
  return (
    <div className={styles.categoryItem}>
      {item.value === true ? (
        <CheckOutlined className={styles.checkIcon} />
      ) : (
        <CloseOutlined className={styles.closeIcon} />
      )}
      <Typography.Text className={styles.evaluableText}>
        {item.evaluable}
      </Typography.Text>
    </div>
  );
};
