import { Divider, Progress, Typography } from "antd";
import { Modal } from "antd";
import React, { useMemo } from "react";
import { EvaluableCategoryList } from "./EvaluableCategoryList";
import styles from "./styles.module.css";
import __ from "../../../../app/i18n";

export const CustomSamuScoreModal = ({ meeting, open, onClose }) => {
  let title = "Score";

  const evaluablesResult = useMemo(() => {
    const result = {
      cantTotalProperties: 0,
      cantPropertiesInTrue: 0,
    };

    (meeting.customSamuScore.evaluables || []).forEach((evaluable) => {
      evaluable.items.forEach((item) => {
        result.cantTotalProperties++;
        if (item.value) {
          result.cantPropertiesInTrue++;
        }
      });
    });

    return result;
  }, [meeting.customSamuScore]);

  return (
    <Modal
      width={1000}
      open={open}
      cancelButtonProps={{ hidden: true }}
      onCancel={onClose}
      footer={false}
    >
      <div className={styles.modalContent}>
        <div className={styles.progressItem}>
          <Typography.Title level={5}>
            {__("meetings.customScore.score")}
          </Typography.Title>
          <Progress type="circle" percent={(meeting.customSamuScore.score)?.toFixed(1) * 10} format={x => x / 10} />
        </div>
        <div className={styles.progressItem}>
        <Typography.Title level={5}>
          {__("meetings.customScore.totalItemsFound")}
        </Typography.Title>
        <Progress
          type="circle"
          percent={
            (evaluablesResult.cantPropertiesInTrue * 100) /
            evaluablesResult.cantTotalProperties
          }
          format={() =>
            evaluablesResult.cantPropertiesInTrue +
            "/" +
            evaluablesResult.cantTotalProperties
          }
        />
        </div>
       
        <div className={styles.scoreDetails}>
          <EvaluableCategoryList
            categoryList={meeting.customSamuScore.evaluables ?? []}
          />
        </div>
      </div>
    </Modal>
  );
};
