import React from "react";
import { Collapse } from "antd";

import { EvaluableCategoryItem } from "./EvaluableCategoryItem";
import styles from "./styles.module.css";
const { Panel } = Collapse;

export const EvaluableCategoryList = ({ categoryList }) => {
  return (
    <div>
      {(categoryList || []).map((evaluable, index) => (
        <Collapse
          collapsible="header"
          defaultActiveKey={["1"]}
          className={styles.evaluableModalPanel}
        >
          <Panel
            header={
              <>
                <span>{evaluable.categoryName}</span>
                <span className={styles.categoryEvaluableScore}>
                  {evaluable.items.filter((i) => i.value).length}/
                  {evaluable.items.length}
                </span>
              </>
            }
            key={`${index}_evaluable`}
          >
            {evaluable.items.map((item, index) => (
              <EvaluableCategoryItem item={item} key={index} />
            ))}
          </Panel>
        </Collapse>
      ))}
    </div>
  );
};
