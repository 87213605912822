import { Button, Checkbox, Form, Select, Switch, Typography } from "antd";
import _ from "lodash";
import { useState } from "react";
import AppItem from "../AppItem";

function NotLoggedInForm(props) {
  return (
    <AppItem app={props.app} canSave={false} canToggleStatus={false}>
      <Button>
        <a href={props.app.loginUrl}>Conectar</a>
      </Button>
    </AppItem>
  );
}

function App(props) {
  const [enabled, setEnabled] = useState(props.app.enabled);
  const [pipelines, setPipelines] = useState(props.app.selectedPipelines || []);
  const [logMeetingEnabled, setLogMeetingEnabled] = useState(
    props.app.actions.logMeeting
  );
  const [logMeetingAsNoteEnabled, setLogMeetingAsNoteEnabled] = useState(
    props.app.actions.logMeetingAsNote
  );
  const [createActionItemsAsTasksEnabled, setCreateActionItemsAsTasksEnabled] =
    useState(props.app.actions.createActionItemsAsTasks);
  const [updateCallPropertiesEnabled, setUpdateCallPropertiesEnabled] =
    useState(props.app.actions.updateCallProperties);
  const [associateCompanyForMeeting, setAssociateCompanyForMeeting] = useState(
    props.app.actions.associateCompanyForMeeting
  );
  const [associateDealForMeeting, setAssociateDealForMeeting] = useState(
    props.app.actions.associateDealForMeeting
  );
  const [associateTicketForMeeting, setAssociateTicketForMeeting] = useState(
    props.app.actions.associateTicketForMeeting
  );
  const [associateCompanyForNote, setAssociateCompanyForNote] = useState(
    props.app.actions.associateCompanyForNote
  );
  const [associateDealForNote, setAssociateDealForNote] = useState(
    props.app.actions.associateDealForNote
  );
  const [associateTicketForNote, setAssociateTicketForNote] = useState(
    props.app.actions.associateTicketForNote
  );

  if (!props.app.isLoggedIn) {
    return <NotLoggedInForm {...props} />;
  }

  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
      pipelines,
      actions: {
        ...props.app.actions,
        logMeeting: logMeetingEnabled,
        logMeetingAsNote: logMeetingAsNoteEnabled,
        createActionItemsAsTasks: createActionItemsAsTasksEnabled,
        associateCompanyForMeeting,
        associateDealForMeeting,
        associateTicketForMeeting,
        associateCompanyForNote,
        associateDealForNote,
        associateTicketForNote,
        updateCallProperties: updateCallPropertiesEnabled
      },
    };

    props.handleOnSave(newSettings);
  };

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => setEnabled(e)}
      canSave={props.app.isLoggedIn}
      onSave={handleSave}
      canToggleStatus={props.app.isLoggedIn}
      enabled={enabled}
    >
      <Form>
        <Typography.Title level={5}>Configuración</Typography.Title>

        <Form.Item label="Pipelines" style={{ marginTop: "15px" }} required>
          <Select
            mode="multiple"
            value={pipelines}
            onChange={(v) => setPipelines(v)}
          >
            {(props.app.pipelines || []).map(({ id, label }) => (
              <Select.Option key={id}>{label.toUpperCase()}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Typography.Title level={5}>Automatizaciones</Typography.Title>
        <div>
          <Checkbox
            checked={logMeetingEnabled}
            onChange={(e) => setLogMeetingEnabled(e.target.checked)}
          >
            Registrar reunión desde Samu en HubSpot como "Meeting"
          </Checkbox>
          {logMeetingEnabled ? (
            <div style={{ marginLeft: "50px" }}>
              <div>
                <Checkbox checked={true}>Asociar Contacto</Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateCompanyForMeeting}
                  onChange={(e) =>
                    setAssociateCompanyForMeeting(e.target.checked)
                  }
                >
                  Asociar Company
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateDealForMeeting}
                  onChange={(e) => setAssociateDealForMeeting(e.target.checked)}
                >
                  Asociar Deal
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateTicketForMeeting}
                  onChange={(e) => {
                    console.log("associateTicketForMeeting", e.target.checked);
                    setAssociateTicketForMeeting(e.target.checked);
                  }}
                >
                  Asociar Ticket
                </Checkbox>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div style={{ marginTop: "15px" }}>
          <Checkbox
            checked={logMeetingAsNoteEnabled}
            onChange={(e) => setLogMeetingAsNoteEnabled(e.target.checked)}
          >
            Registrar reunión desde Samu en HubSpot como "Nota"
          </Checkbox>
          {logMeetingAsNoteEnabled ? (
            <div style={{ marginLeft: "50px" }}>
              <div>
                <Checkbox checked={true}>Asociar Contacto</Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateCompanyForNote}
                  onChange={(e) => setAssociateCompanyForNote(e.target.checked)}
                >
                  Asociar Company
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateDealForNote}
                  onChange={(e) => setAssociateDealForNote(e.target.checked)}
                >
                  Asociar Deal
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={associateTicketForNote}
                  onChange={(e) => setAssociateTicketForNote(e.target.checked)}
                >
                  Asociar Ticket
                </Checkbox>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <Checkbox
            checked={createActionItemsAsTasksEnabled}
            onChange={(e) => setCreateActionItemsAsTasksEnabled(e.target.checked)}
          >
            Registrar compromisos detectados como tareas en HubSpot
          </Checkbox>
        </div>

        {props.app.actions.canUpdateCallProperties && (
          <div style={{ marginTop: "15px" }}>
            <Checkbox
              checked={updateCallPropertiesEnabled}
              onChange={(e) => setUpdateCallPropertiesEnabled(e.target.checked)}
            >
              Completar detalles de llamada (HubSpot Calling) con propiedades
              extraidas
            </Checkbox>
          </div>
        )}
      </Form>
    </AppItem>
  );
}

export default App;
